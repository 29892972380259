import { NodeAPIs } from "../utils/worker";
const { nodeBaseURL } = NodeAPIs;
const nodeAPI = {
  GET_EXCEPTION_REPORT: nodeBaseURL + "/exceptions",
  GET_EXCEPTION_ACTIONS: nodeBaseURL + "/actions",
  GET_EXCEPTION_REASONS: nodeBaseURL + "/reasons",
  UPDATE_EXCEPTION_REPORT: nodeBaseURL + "/exceptions/",
  GET_ALL_BAG_LIST: nodeBaseURL + "/bag-list",
  SHIPMENT_TRACKER_NODE: nodeBaseURL + "/tracking/",
  MANIFEST_DATE_FILTER_NODE: nodeBaseURL + "/consolidated-shipment",

  CREATE_NEW_ROUTE: nodeBaseURL + "/route",
  UPDATE_NEW_ROUTE: nodeBaseURL + "/route/",
  SHIPMENT_COUNT: nodeBaseURL + "/shipment-count",

  SEARCH_ROUTES: nodeBaseURL,
  NEW_LCR_REPORT: nodeBaseURL + "/lcr_dashboard",
  LCRTableDetails: nodeBaseURL + "/lcr_customised_view?vehicleLCRId=",
  LCR_DETAILS: nodeBaseURL + "/bag_shipment_details?barcodeNumber=",
  LCR_BAG_SHIPEMNT_DETAILS:
    nodeBaseURL + "/bag_shipment_details?BagOrShipmentNumber=",
};
export default nodeAPI;
