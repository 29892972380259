/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Form, Select } from "antd";
import "antd/dist/antd.css";

const CompanySelection = ({
  companies,
  handleRedirectDashboard,
  setSelectedCompany,
}) => {
  return (
    <>
      <div className="login-container-headding">Please select your company</div>
      <Form
        autoComplete="on"
        onFinish={() => {
          handleRedirectDashboard();
        }}
      >
        <div className="login-container-email" style={{ marginTop: 20 }}>
          Company name
        </div>
        <Form.Item
          name="travelBy"
          rules={[
            {
              required: true,
              message: "Select  value",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select company"
            onSelect={(e, l) => setSelectedCompany(l)}
            options={companies}
          />
        </Form.Item>
        <Form.Item>
          <div className="login-container-btndiv">
            <Button
              className="save-btn mt-2"
              size="large"
              htmlType="submit"
              block
            >
              Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default CompanySelection;
