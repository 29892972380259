// eslint-disable import/no-anonymous-default-export
export default {
  dev: {
    API: {
      // baseURL: "https://devhubopsapis.innofulfill.com/smcs-webapp",
      // bffBaseURL: "https://devhubopsapis.innofulfill.com",
      baseURL: "https://smcs-dev.hutechweb.com/smcs-webapp",
      bffBaseURL: "https://smcs-dev.hutechweb.com",
      baseURLInnofulfill: "https://bookingapis.innofulfill.com",
    },
    auth: {
      domain: "smcs-prod.hutechweb.com",
      oauthDomain: "SMCS_POOL.auth.us-east-1.amazoncognito.com",
      userPoolWebClientId: "5764umttfqiplob9e337ar0v0m",
      userPoolId: "us-east-1_TD8m0Yrs9",
      region: "us-east-1",
    },
  },
  qa: {
    API: {
      baseURL: "https://qaapis.hubops.innofulfill.com/smcs-webapp",
      bffBaseURL: "https://qaapis.hubops.innofulfill.com",
      baseURLInnofulfill: "https://bookingapis.innofulfill.com",
    },
    auth: {
      domain: "qaapis.hubops.innofulfill.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "a3e0iaciccfeln7no5b467ab5",
      userPoolId: "ap-south-1_xoRMaTXJu",
      region: "ap-south-1",
    },
  },
  uat: {
    API: {
      baseURL: "https://uatapis-hubops.innofulfill.com/smcs-webapp",
      bffBaseURL: "https://uatapis-hubops.innofulfill.com",
      baseURLInnofulfill: "https://bookingapis.innofulfill.com",
    },
    auth: {
      domain: "smcs-uat.hutechweb.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "a3e0iaciccfeln7no5b467ab5",
      userPoolId: "ap-south-1_xoRMaTXJu",
      region: "ap-south-1",
    },
  },
  prod: {
    API: {
      //baseURL: "https://apis-hubops.innofulfill.com/smcs-webapp",
      baseURL: "https://apis.bookings.innofulfill.com/smcs-webapp",
      //bffBaseURL: "https://apis-hubops.innofulfill.com",
      bffBaseURL: "https://apis.bookings.innofulfill.com",
      baseURLInnofulfill: "https://bookingapis.innofulfill.com",
    },
    auth: {
      domain: "smcs-prod.hutechweb.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "4q270kj0hhe1jimi5tbopv2vts",
      userPoolId: "ap-south-1_7Q0AtPCUn",
      region: "ap-south-1",
    },
  },
};
