import { APIs } from "../utils/worker";
const { baseURL, bffBaseURL, baseURLInnofulfill } = APIs;
const urls = {
  GET_PINCODE: "https://api.postalpincode.in/pincode", //GET
  SEND_WHATSPP: "https://backend.api-wa.co/campaign/smartping/api", //POST
  UPLOAD_MANIFEST_FILE:
    baseURL + "/shipment-booking-service/v1/shipment/file-upload",
  GET_VEHICLE_ROUTES: baseURL + "/vehicle-route-service/v1/getAllRoute/SURFACE",
  // GET_VEHICLE_ROUTES: baseURL + "/route-service/v1/getRouteBypremise",

  UPDATE_SORTING_GUIDE:
    baseURL + "/sorting-service/v1/sorting-guide/premise-id",
  GET_SORTING_GUIDE_DETAIL:
    baseURL + "/sorting-service/v1/sorting-guide/sorting-guide-id/",
  GET_SORTING_NAME_BASED_DETAIL:
    baseURL + "/sorting-service/v1/sorting-guide/premise-id/",
  GET_CLIENT_DETAILS: baseURL + "/client-onboard/v1/organizations/",
  PATCH_CLIENT_DETAILS: baseURL + "/client-onboard/v1/organizations/",
  UPDATE_STAFF: baseURL + "/staff-service/v1/staff",
  VERIFY_GST: baseURL + "/publicapi-service/v1/gst/",
  PINCODE_MAPPING_PATCH: baseURL + "/client-onboard/v1/pin-code-mapping/",
  GET_CLIENT_TABLE_HO: baseURL + "/client-onboard/v1/ho/clients",
  GET_CLIENT_TABLE: baseURL + "/client-onboard/v1/cp/clients",
  GET_BANK_DETAILS_BY_IFSC: baseURL + "/staff-service/v1/ifscdetail",
  DISABLE_CLIENT: baseURL + "/client-onboard/v1/disable/",
  VERIFY_EMAIL: baseURL + "/staff-service/v1/verifykey",
  VERIFY_PHONE_NUM: baseURL + "/staff-service/v1/verifykey",
  SEND_SMS_PHONE: baseURL + "/driver-service/v1/driver/sendsms",
  GET_ALL_STAFF: baseURL + "/staff-service/v1/staff",
  GET_STAFF_ROLES: baseURL + "/staff-service/v1/staff/roles",
  GET_NOTIFICATION1: baseURL + "/notification-service/v1/getNotification/",
  GET_NOTIFICATION: baseURL + "/notification-service/v1/stream-flux",
  VIEW_NOTIFICATION: baseURL + "/notification-service/v1/updateView",

  // VERIFY_PHONE_OTP: baseURL + "/v1/driver/verifysms",
  VERIFY_PHONE_OTP: baseURL + "/driver-service/v1/driver/verifysms",

  VERIFY_EMAIL_OTP: baseURL + "/v1/driver/verifymail",
  UPLOAD_CLIENT_DOC: baseURL + "/file-service/v1/client/document",
  UPLOAD_DOC_TO_CLIENT: baseURL + "/client-onboard/v1/document/",
  ADD_CLIENT_RATE_CARD: baseURL + "/ratecard-service/v1/ratecard",
  ADD_LEAD_CLIENT_RATE_CARD: baseURL + "/lead-service-client/v1/client/lead/",

  GET_CLIENT_RATE_CARD: baseURL + "/ratecard-service/v1/ratecard/client/",
  APPROVE_CLIENT: baseURL + "/ratecard-service/v1/ratecard/",
  REJECT_CLIENT: baseURL + "/client-onboard/v1/client/decline",
  GET_RATE_CARD: baseURL + "/ratecard-service/v1/ratecard/calculate/from/",
  GET_NEW_PASSOWORD_DETAILS: baseURL + "/account-service/v1/user/",
  CREATE_NEW_PASSWORD: baseURL + "/api/users/sign-up",
  GET_CLIENT_BY_CP: baseURL + "/client-onboard/v1/cp/clients",
  GET_ALL_RATE_CARD_LIST: baseURL + "/ratecard-service/v1/ratecards",
  //--Middle Mile--//
  SORT_SHIPMENT: baseURL + "/sorting-service/v1/sort-plan",
  GET_MM_ROUTE: baseURL + "/route-service/v1/getroutebypremise/",
  CREATE_BAG: baseURL + "/bagging-service/v1/bag",
  FINALISE_BAG: baseURL + "/bagging-service/v1/finalizebag/",
  GET_BAG_NUMBER: baseURL + "/bagging-service/v1/bag/bag-barcode",
  GET_ELIGIBLE_DESTINATION_HUB:
    baseURL + "/bagging-service/v1/bag/destinationhublist",
  // GET_ALL_BAGS: baseURL + "/bagging-service/v1/bag",
  GET_ALL_BAGS: baseURL + "/bagging-service/v1/bag/eligibleBags",
  DELETE_BAG_SHIPMENTS: baseURL + "/bagging-service/v1/bag",
  DELETE_BAG: baseURL + "/bagging-service/v1/bag/discard/",
  GET_BAG_DESTINATION: baseURL + "/route-service/v1/route/from/",
  POST_TRIP_GENERATE: baseURL + "/lcr-service/v1/trip/vehicle",
  GET_ALL_ROUTS: baseURL + "/route-service/v1/getroutebypremise/",
  POST_LCR: baseURL + "/lcr-service/v1/lcr",
  POST_CONSOLIDATE_LCR: baseURL + "/lcr-service/v1/consolidate/lcr",
  PATCH_CONSOLIDATED_LCR: baseURL + "/lcr-service/v1/consolidated/lcr/",
  GET_LCR_LIST: baseURL + "/lcr-service/v1/consolidated/lcr/list",
  GET_TRIP_LIST: baseURL + "/lcr-service/v1/trips",
  SEARCH_TRIP_LIST: baseURL + "/lcr-service/v1/trips/search/",
  UPDATE_TRIP_LIST: baseURL + "/lcr-service/v1/transit/lcr-trips",
  GET_TRIP_COUNT: baseURL + "/lcr-service/v1/trip/tripId",
  POST_CHECKOUT_TIME: baseURL + "/lcr-service/v1/mm/manual/",
  GET_ROUT_AND_TRIP_SCHEDULE:
    baseURL + "/surface-route-service/v1/getTripScheduled/forDate/",
  GET_ROUT_AND_TRIP_SCHEDULE_SURFACE:
    baseURL + "/surface-route-service/v1/trip/without-schedule/",
  //----//
  GET_ALL_CP_FROM_HUB: baseURL + "/manifest-service/v1/consolidated-manifest/",
  // GET_ALL_CP_FROM_HUB: baseURL + "/audit-service/v1/audit/premise",
  MANIFEST_DATE_FILTER:
    baseURL + "/manifest-service/v1/consolidated-manifest/byDate/",
  GET_CP_WISE_MANIFEST:
    baseURL + "/manifest-service/v1/manifest/status/MANIFESTED/hub/",
  GET_CP_WISE_MANIFEST_HEADER:
    baseURL + "/manifest-service/v1/manifest/status/MANIFESTED/hub/",
  GET_CP_WISE_MANIFEST_TABLE:
    baseURL + "/manifest-service/v1/manifest/status/MANIFESTED/hub/",
  AUDIT_POST: baseURL + "/audit-service/v1/web-client/audit",

  GET_SHIPMENT:
    baseURL + "/audit-service/v1/web-client/audit/shipment-details/",
  // https://smcs-dev.hutechweb.com/smcs-webapp/audit-service/v1/audit/shipment-details/%7BawbNo%7D

  GET_TRACKER: baseURL + "/track/v1/awbnumber/",
  GET_EWAY_TRACKER: baseURL + "/ewaybill-service/v1/eway-bills/track/",

  GET_CP_DETAILS: baseURL + "/premise-service/v1/cpDetails/",

  //LCR IN_SCAN
  GET_LCR_IN_SCAN_LIST: baseURL + "/lcr-service/v1/lcr/list/toPremise",
  GET_LCR_IN_SCAN_LIST_NEW: baseURL + "/lcr-service/v1/lcr-list/toPremise",
  SCAN_LCR_IN_SCAN_LIST: baseURL + "/lcr-service/v1/lcr",

  //LCR Bag list
  // GET_LCR_BAG_LIST: baseURL + "/bagging-service/v1/bag/update/bag/list",

  GET_LCR_SCANNED_PACKETS: baseURL + "/v1/packets/in-scan/list/",

  GET_LCR_BAG_LIST: baseURL + "/lcr-service/v1/lcr/",
  GET_LCR_SCANNED_BAGS: baseURL + "/bagging-service/v1/bag/in-scan/list",
  GET_BAGGED_CONTENTS: baseURL + "/bagging-service/v1/bag/",
  GET_PACKET_CONTENTS: baseURL + "/v1/packets/",
  GET_ROUT_BY_ID: baseURL + "/route-service/v1/getroutebyid/",
  PUT_TRIP_STATUS: baseURL + "/lcr-service/v1/trip/",
  //BAG SCAN
  UPDATE_ALL_BAGs: baseURL + "/inscan-service/v1/barcode/in-scan/",
  UPDATE_ALL_BAGS_NEW: baseURL + "/inscan-service/v2/barcode/in-scan/",
  UPDATE_ALL_PACKETS: baseURL + "/v1/packets/update/packets/status",

  GET_BAG_OPENED: baseURL + "/bagging-service/v1/bag/status/opened",

  //PRODUCT
  GET_ALL_PRODUCTS: baseURL + "/item",
  GET_PRODUCTS_BY_PREMISE: baseURL + "/item/v1/eligibility",
  GET_PRODUCT_BY_ID: baseURL + "/item/v1/",
  GET_PRODUCT_BY_ORDER_ID: baseURL + "/order/v1/orderdetailOf/",
  APPROVE_CANCEL_ORDER: baseURL + "/order/v1/update/orderitem-status/",
  APPROVE_ALLOCATE_ORDER: baseURL + "/order/v1/allocate-barcode/",
  BARCODE_TYPE: baseURL + "/item/v1/barcode-series/type",

  ORDER_CANCEL_REASON: baseURL + "/order/v1/cancel-reason",

  ADD_TO_CART: baseURL + "/cart/v1/add",
  GET_CART_DETAILS: baseURL + "/cart",
  UPDATE_DELIVERY_DETAILS: baseURL + "/cart/v1/delivery",
  REMOVE_ITEM: baseURL + "/cart/v1/remove/item/",
  UPDATE_PAYMENT_DETAILS: baseURL + "/cart/v1/payment",
  PLACE_ORDER: baseURL + "/order",
  UPDATE_ITEM_DATA: baseURL + "/cart/v1/edit-quantity",
  ORDER_LIST: baseURL + "/order/v1/orders",
  ORDER_DETAILS_BY_ID: baseURL + "/order/v1/",
  MODE_PAYMENT_DROPDOWN: baseURL + "/cart/v1/mode-of-payment",

  //SORT TO CP
  GET_CP_WISE_SORTING_DATA: baseURL + "/sort-and-out-scan",
  UPDATE_CP_WISE_SORTING_STATUS: baseURL + "/sort-and-out-scan/v1/",

  //lastMile
  GET_CP_IN_SCAN_LIST:
    baseURL + "/last-mile-manifest-service/v1/manifest/toPremise/",
  GET_MANIFEST_LIST: baseURL + "/last-mile-manifest-service/v1/manifest",
  HUB_MANIFEST_LIST: baseURL + "/sort-to-cp/v1/manifest/view/eway",
  UPDATE_LASTMILE_EWAYS: baseURL + "/sort-to-cp/v1/update-ewaybill/",

  GET_CP_MANIFEST_LIST:
    baseURL + "/last-mile-manifest-service/v1/manifest/toPremise/",
  DELETE_DRS: baseURL + "/drs-service/v1/delete/drs/",

  // /premise/areas/{premiseId}
  GET_DELIVERY_AREAS: baseURL + "/premise-service/premise/areas/",
  GET_VIEW_ALL_DELIVERY_AREAS: baseURL + "/pincode-service/v1/virtual-areas",
  CREATE_DELIVERY_AREA: baseURL + "/pincode-service/v1/virtual-area",
  GET_VIRTUAL_AREA: baseURL + "/pincode-service/v1/virtual-area/",
  DELETE_VIRTUAL_AREA: baseURL + "/pincode-service/v1/virtual-area/",
  POST_ELIGIBLE_SHIPMENTS: baseURL + "/drs-service/v1/elligible/shipments",
  POST_GENERATE_DRS: baseURL + "/drs-service/v1/drs",
  UPDATE_VIRTUAL_AREA: baseURL + "/pincode-service/v1/virtual-area/",
  GET_DELIVERY_BOY:
    baseURL + "/driver-service/v1/driver/getDeliveryBoy/details",
  GET_DRS_LIST: baseURL + "/drs-service/v1/drs/premise/list/",
  GET_SINGLE_DRS: baseURL + "/drs-service/v1/drs/",
  GET_POD: baseURL + "/drs-service/v1/drs/pod/",
  PATCH_DRS_SHIPMENT: baseURL + "/drs-service/v1/drs/",
  PENDING_DRS: baseURL + "/shipment-service/v1/shipment/pending",
  FAILED_DRS: baseURL + "/shipment-service/v1/shipment/failed/delivery",
  PENDING_DRS_SEARCH: baseURL + "/shipment-service/v1/shipment/pending",
  REMOVE_VIRTUAL_AREA: baseURL + "/premise-service/premise/area/status",
  VIEW_DRS_SEARCH: baseURL + "/drs-service/v1/search?",
  DRIVERNAME_BY_ID: baseURL + "/driver-service/v1/driver/getdriver/bydriverId/",

  // IN_CP_GET_TABLE: baseURL + "/last-mile/in-scan/v1//",
  // smcs-webapp/pincode-service/v1/virtual-area/{virtualAreaId}

  //PACKERING
  GET_CP_PACKETS: baseURL + "/v1/packets/eligible/packets",
  GET_HUB_PACKETS: baseURL + "/v1/packets/hub/eligible/packets",

  CREATE_PACKETS: baseURL + "/v1/packets/",
  UPDATE_PACKET: baseURL + "/v1/packets/",
  UPDATE_HUB_PACKET: baseURL + "/v1/packets/hub/",

  EWAYBILL: baseURL + "/ewaybill-service/v1/eway-bills",

  HUBEWAYBILL: baseURL + "/lcr-service/v1/lcr/ewaybill/shipments/",

  ///--Bagging Automate--///
  //NEW_TYPE_BAG_CREATE: baseURL + "/bagging-service/v1/bag/eligible-content",
  NEW_TYPE_BAG_CREATE: baseURL + "/bagging-service/v1/createbag",
  LIST_OF_BAGS: baseURL + "/bagging-service/v1/bag/bag-list",
  CREATE_EMPTY_BAG: baseURL + "/bagging-service/v1/bag/create-empty-bag",
  CHECK_VALID_BAG_NUMBER:
    baseURL + "/bagging-service/v1/bag/valid-bag-barcode/",
  ///----///
  ///--Damage Bag--///
  DAMAGE_REASON_LIST: baseURL + "/bagging-service/v1/damage/reason",
  POST_DAMAGED_BAG: baseURL + "/bagging-service/v1/damagedbag",
  POST_DAMAGED_BAG_DATA: baseURL + "/bagging-service/v1/damage/add/",
  POST_LEFT_BAG_DATA: baseURL + "/bagging-service/v1/damage/shipment",
  ///---///
  //firstMile
  CP_EWAY_BILL_TABLE: baseURL + "/manifest-service/v1/manifest/",
  CONSOLIDATE_EWAY_BILL:
    baseURL + "/ewaybill-service/v1/eway-bills/consolidate",
  EWAYBILL_UPDATE_PARTB: baseURL + "/ewaybill-service/v1/eway-bills/",
  EXTEND_EWAY_VALIDITY: baseURL + "/ewaybill-service/eWayApi_Extend_Validity",
  GET_EWAY_VALIDITY:
    baseURL + "/shipment-booking-service/v1/shipment/extend/validity",
  POST_EWAY_VALIDITY:
    baseURL + "/ewaybill-service/v1/eway-bills/extend/validity",
  UPDATED_EWAY_BILL: baseURL + "/ewaybill-service/v1/validate/eway-bills/by/",

  //HUB SORT TO CP EWAY BILL
  SORT_CP_UPDATED_EWAY_BILL:
    baseURL + "/ewaybill-service/v1/validate/eway-bills/by/lastmilemanifest/",

  QR_FOR_CHECK_IN: baseURL + "/lcr-service/v1/mm/qr/CHECK-IN/for/",
  VEHICLE_CHECKIN_CHECKOUT_REPORT:
    baseURL + "/lcr-service/v1/trip/check/In_Out/report",

  CP_OTP: baseURL + "/premise-service/v1/sendOtp ",
  VERYIFY_CP_OTP: baseURL + "/premise-service/v1/verifyOtp",

  //---Trip Scheduler--//
  GET_TRIP_SCHEDULER_LIST:
    baseURL + "/surface-route-service/v1/getTripSchedule/from/",
  GET_ALL_SCHEDULED_VECHILE:
    baseURL + "/surface-route-service/v1/getAllSchedules/forScheduling",
  DROP_SCHEDULE: baseURL + "/surface-route-service/v1/saveSchedule",
  //create Schedule
  GET_SCHEDULE_VEHICLES: baseURL + "/surface-route-service/v1/getvehicles",
  GET_SCHEDULE_ROUTES: baseURL + "/surface-route-service/v1/getRoutes",
  GET_SCHEDULE_DRIVERS: baseURL + "/surface-route-service/v1/getDrivers/",

  CREATE_SCHEDULE: baseURL + "/surface-route-service/v1/updateTripSchedule/by/",
  GET_ROUTE: baseURL + "/surface-route-service/v1/getRoute/by/",
  GET_VEHCILE_MASTERS: baseURL + "/surface-route-service/v1/getVehicleMasters",

  GET_TRIP_SCHEDULE_DATA:
    baseURL + "/surface-route-service/v1/getTripSchedules/by/",
  GET_TRIP_SCHEDULE_BY_TYPE:
    baseURL + "/surface-route-service/v1/getroute/for/",
  DELETE_TRIP_SCHEDULE:
    baseURL + "/surface-route-service/v1/deleteTripSchedule/by/",

  //--End--//

  GET_ALL_STATE: baseURL + "/pincode-service/v1/get/states",
  GET_ALL_HUBS: baseURL + "/tat-service/v1/tat/getAllHubs",
  LOADING_UNLOADING: baseURL + "/tat-service/v1/tat/saveData",
  GET_ALL_LOAD_UNLOAD: baseURL + "/tat-service/v1/tat/getAllLoadUnloads",
  GET_CITY: baseURL + "/premise-service/v1/cities/by/",
  VEHICLE_RESTRICTION: baseURL + "/tat-service/v1/add/vehicle/restriction",
  GET_RESTRICTION_LIST: baseURL + "/tat-service/v1/vehicle/restriction/list",
  DELETE_CEHICLE_RESTRICTION:
    baseURL + "/tat-service/v1/delete/vehicle/restriction/",
  EDIT_CEHICLE_RESTRICTION:
    baseURL + "/tat-service/v1/update/vehicle/restriction/",
  UPDATE_LOAD: baseURL + "/tat-service/v1/tat/updateLoad/",
  GET_CONFIG_LIST: baseURL + "/admin-config/v1",
  GET_CONFIG_LIST_UPDATE: baseURL + "/admin-config/v1/update/",
  // UPDATE_BREAK_TIME: baseURL + "/admin-config/v1",
  AUDIT_EWAY_BILL: baseURL + "/ewaybill-service/v1/audit",
  EWAYBILL_PRINT: baseURL + "/ewaybill-service/v1/generate/consolidate/report/",
  CP_WISE_HUB_PRINT: baseURL + "/sort-to-cp/v1/print-response/",

  AUDIT_EXCEL_DOWNLOAD:
    baseURL + "/audit-service/v1/web-client/audit-report/download",
  AUDIT_EXCEL_DOWNLOAD_HO:
    baseURL + "/audit-service/v1/web-client/audit-report/ho/download",

  GET_MIS_ROUTED_SHIPMENT: baseURL + "/inscan-service/v1/shipment/mis-routed",
  GET_EXCESS_IN_SCAN: baseURL + "/inscan-service/v1/shipment/excess-in-scan",
  // Hub onboarding
  GET_HUB_PINCODE: baseURL + "/pincode-service/v1/pincode/",
  GET_HUB_NAME_VALIDATE: baseURL + "/premise-service/v1/premise/hub-name/",
  GET_HUB_EMAIL_VALIDATE: baseURL + "/premise-service/v1/premise/hub-email/",
  GET_HUB_GST_VALIDATE: baseURL + "/premise-service/v1/premise/hub-gst/",
  GET_HUB_PINCODE_VALIDATE:
    baseURL + "/premise-service/v1/premise/hub-pincode/",
  POST_CREATE_HUB: baseURL + "/premise-service/v1/premise/hub",
  PATCH_CP_ALLOCATION: baseURL + "/premise-service/v1/premise/hub/",
  HUB_LIST_SEARCH: baseURL + "/premise-service/v1/premises/hub/search/",
  GET_EXPAND_HUB: baseURL + "/premise-service/v1/premise/hub/",
  GET_EXPAND_HUB_CP: baseURL + "/premise-service/v1/premise/hub-cp/",
  GET_EXCESS_FROM: baseURL + "/tat-service/mm-tat/from-premise/",

  //RTO
  GET_RTO_SHIPMENT_BY_AWBNUMBER: baseURL + "/shipment-service/v1/shipment/",
  UPDATE_CASH: baseURL + "/drs-service/v1/rto/shipment/",
  PUT_RTO_SERVICE: baseURL + "/drs-service/v1/rto/",
  PUT_RATECARD_RTO: baseURL + "/ratecard-service/v1/ratecard/calculate/from/",
  GET_CPTYPE_CHECKBOX: baseURL + "/premise-service/v1/for/CP/and/type/",
  GET_TAT_DOWNLOAD_BUTTON: baseURL + "/tat-service/v1/export/mm/",

  GET_VEHICLE_FEET: baseURL + "/tat-service/v1/get/vehiclefeet/type",
  GET_CALENDER_VIEW_TRIP:
    baseURL + "/surface-route-service/v1/viewTripSchedules/by/",

  GET_TRIP_UTILIZAION: baseURL + "/lcr-service/v1/consolidated/lcr/trip/",
  DAMAGE_SHIPMENTS: baseURL + "/lcr-service/v1/getall/eliminated/lcr/contents/",
  UPDATE_DAMAGE_SHIPMENTS:
    baseURL + "/lcr-service/v1/update/eliminated/lcr/contents/by/",
  UPDATE_MISSROUTE_SHIPMENTS:
    baseURL + "/shipment-service/v1/update/mis-routed/shipment/",
  GET_LEAD_DETAILS: baseURL + "/lead-service/v1/lead",
  CLIENT_LEAD_LIST: baseURL + "/lead-service-client/v1/client/leads?",

  //lead cp onboard //
  POST_LEAD_CP_DETAILS: baseURL + "/lead-service/v1/lead",
  GET_LEAD_CP_QUOTE_TABLE: baseURL + "/lead-service/v1/quotations/",
  GET_LEAD_CP_QUOTE_BY_ID: baseURL + "/lead-service/v1/quotes/",
  GET_LEAD_CP_BILLING_CONFIG_ID:
    baseURL + "/ratecard-service/v1/billing-config/",

  UPDATE_LEAD_CP_DETAILS: baseURL + "/lead-service/v1/lead",
  GET_ALL_CP_LEADS: baseURL + "/lead-service/v1/leads",
  GET_CP_LEAD_ID: baseURL + "/lead-service/v1/lead/",
  GET_ALL_AREAS: baseURL + "/pincode-service/v1/",
  UPDATE_LEAD_CLIENT_DETAILS: baseURL + "/lead-service-client/v1/client/lead/",
  GET_ALL_CP_HUBS: baseURL + "/premise-service/v1/premises/allhub",
  //lead cp onboard //

  UPLOAD_LEAD_CLIENT_FILE: baseURL + "/file-service/v1/client/document",
  LEAD_CLIENT_DOCUMENT:
    baseURL + "/lead-service-client/v1/client/lead/document/",
  LEAD_CLIENT_LIST: baseURL + "/lead-service-client/v1/client/leads",
  LEAD_CP_LIST: baseURL + "/lead-service/v1/leads",
  LEAD_LIST: baseURL + "/lead-service/v1/leads",
  LEAD_APPROVAL: baseURL + "/lead-service/v1/lead/approval",
  GET_LEAD_CLIENT_PINCODE_MAPPING:
    baseURL + "/lead-service-client/v1/pin-code/",
  PATCH_LEAD_CLIENT_PINMAP:
    baseURL + "/lead-service-client/v1/pin-code-mapping/",
  LEAD_CP_UPLOAD: baseURL + "/publicapi-service/v1/file/", //POST

  //lead cp onboard //

  // State master
  POST_PLACE_0F_BUSINESS: baseURL + "/state-master-service/v1/statemaster",
  PUT_PLACE_0F_BUSINESS: baseURL + "/state-master-service/v1/statemaster/",
  GET_POB: baseURL + "/state-master-service/v1/statemasters",
  GET_POB_BY_ID: baseURL + "/state-master-service/v1/statemaster/",
  // State masteer

  // Quotation
  CREATE_QUOTE: baseURL + "/lead-service-client/v1/client/lead/{clientLeadId}",
  GET_CLIENT_LEAD_ID: baseURL + "/lead-service-client/v1/client/lead/",
  POST_CP_LEAD_RATE_CARD: baseURL + "/lead-service/v1/ratecard/",
  PATCH_CP_LEAD_RATE_CARD: baseURL + "/lead-service/v1/ratecard/",
  GET_QUOTE_ID: baseURL + "/lead-service-client/v1/client/lead/",
  GET_ALL_RATECARDS: baseURL + "/ratecard-service/v1/ratecards/all",
  GET_SINGLE_RATECARD: baseURL + "/ratecard-service/v1/ratecard/",
  GET_ADDED_RATECARD_CP: baseURL + "/lead-service/v1/ratecard/",
  GET_ALL_QUOTE_LIST: baseURL + "/lead-service-client/v1/client/lead/quotes/",
  GET_DEFAUT_RATE_CARD:
    baseURL + "/retail-ratecard-service/v1/ratecard/default",
  SEND_QUOTE_MAIL: baseURL + "/lead-service-client/v1/client/mail/", // POST
  GET_CLIENT_QUOTATION_DETAILS_BY_ID:
    baseURL + "/lead-service-client/v1/client/",
  UPDATE_CLIENT_RATE_CARD:
    baseURL + "/lead-service-client/v1/client/lead/ratecard/",
  // Quotation
  LEAD_CLIENT_QUOTATION_APPROVAL:
    baseURL + "/lead-service-client/v1/client/approval/",
  // GET_RATE_CARD_DATA_ID: baseURL + "/ratecard-service/v1/ratecard/",

  //
  GST_UPLOAD: baseURL + "/publicapi-service/v1/file/gstCertificate",
  AUDIT_LIST: baseURL + "/audit-service/v1/web-client/audit-report/",
  AUDIT_SEARCH: baseURL + "/audit-service/v1/web-client/audit-report/search",

  GET_DEFAULT_RETAL_RATECARD: baseURL + "/retail-ratecard-service/v1/ratecard/",
  GET_RETAIL_EXISTING_RATECARD:
    baseURL + "/retail-ratecard-service/v1/ratecards",
  GET_DETAILS: baseURL + "/lead-service/v1/",

  GET_RETAIL_EXISTING_RATECARD_BY_ID:
    baseURL + "/retail-ratecard-service/v1/ratecard/",
  GET_RATE_CARD_APPROVAL: baseURL + "/lead-service/v1/lead/approval",

  //ROUTE SERVICES
  CREATE_ROUTE: baseURL + "/route-service/v1/route/from/", //HO

  GET_ALL_ROUTE: baseURL + "/route-service/v1/routes/", //HO
  CREATE_V_ROUTE: baseURL + "/vehicle-route-service/v1/createroute", //HUB
  GET_ALL_V_ROUTE: baseURL + "/vehicle-route-service/v1/routes/", //HUB
  DELETE_V_ROUTE: baseURL + "/vehicle-route-service/v1/route/", //HUB
  UPDATE_MASTER_VEHICLE:
    baseURL + "/surface-route-service/v1/updateSchedule/byId/",
  //

  //Client Approvals

  GET_CLIENT_APPROVAL_LIST:
    baseURL + "/lead-service-client/v1/client/lead/quotes", // GET
  GET_CLIENT_APPROVAL_PINCODE_MAP:
    baseURL + "/pincode-service/v1/pincode-mapping/lead/pin-code-mapping/", // GET
  GET_CLIENT_APPROVAL_RATECARD:
    baseURL + "/lead-service-client/v1/client/ratecard/", //GET
  //Client Approvals

  NEW_INSCAN: baseURL + "/inscan-service/v1/in-scan", //GET
  //LCR
  GET_ALL_VEHICLE_ROUTS: baseURL + "/vehicle-route-service/v1/getAllRoute/", //GET
  GET_ALL_VEHICLES: baseURL + "/vehicle-service/v1/availableVehicles", //GET
  POST_ELIGIBLE_BAGS: baseURL + "/out-scan-service/v1/trip/", //POST
  POST_TRIP_ID: baseURL + "/lcr-service/v1/trips", //POST
  GET_VIEW_TRIP: baseURL + "/lcr-service/v1/consolidated/lcr/trip/", //GET
  LOCK_LCR: baseURL + "/lcr-service/v1/", //PUT
  GET_DRIVER_LIST: baseURL + "/driver-service/v1/driver/getdriver/", //GET
  PUT_DRIVER_DETAILS: baseURL + "/vehicle-service/v1/vehicle/", //PUT
  GET_ALL_LCR_TRIPS: baseURL + "/lcr-service/web/v1/trip-list", // GET
  LOCK_TRIP_LCR: baseURL + "/lcr-service/web/v1/update-vehicle-lcr-status/", // PUT with tripID & status
  TRIP_BASED_SHIPMENT_REPORT: baseURL + "/lcr-service/web/v1/",
  //LCR

  //--SORT TO CP--//
  SORT_LAST_MILE_TYPES: baseURL + "/sort-to-cp/v1/types",
  GET_SORT_LAST_MILE_ELIGIBLE_DATA: baseURL + "/sort-to-cp/v1/eligible/outscan",
  GENERATE_MANIFEST: baseURL + "/sort-to-cp/v1/manifest",
  ALL_MANIFEST_LIST: baseURL + "/sort-to-cp/v1/manifest/view/",
  MANIFEST_CP_LIST: baseURL + "/sort-to-cp/v1/manifest/cp-list/",
  CP_WISE_MANIFEST: baseURL + "/sort-to-cp/v1/manifest/view/cp/",
  //----//

  //----------------CP KYC ----------------------//
  POST_WO_GST: baseURL + "/kyc-verification/v1/wallet/onboard", //POST
  POST_GST: baseURL + "/kyc-verification/v1/wallet/onboard", //POST
  UPDATE_CP_KYC: baseURL + "/kyc-verification/v1/wallet/update/", // PATCH
  VERIFY_KYC_OTP: baseURL + "/kyc-verification/v1/wallet/verify-otp/", // PATCH;
  CP_KYC_SAVE: baseURL + "/kyc-verification/v1/customer/save-kyc", // POST
  GET_KYC_DETAILS: baseURL + "/premise-service/v1/premise-wallet/details", //GET

  //----------------Billing Config and Rate card list-----------//
  GET_RATE_CARD_LIST: baseURL + "/ratecard-service/v1/billing-configs",
  GET_BILLING_NAME_TYPE_CYCLE: baseURL + "/ratecard-service/v1/billing-config",
  GET_CP_RATECARD:
    baseURL + "/ratecard-service/v1/billing-config/ratecard-type/",

  ///-----------------RETAIL BOOKING NEW------------------------///
  CHECK_PIN_CODE_SERVICEABILITY:
    bffBaseURL + "/v1/bff/serviceability/?pinCode=",
  FETCH_RATE_CARD_CP: baseURL + "/ratecard-service/v1/ratecard/calculate/from/",
  GET_CP_TAT_SERVICE: baseURL + "/tat-service/v1/tat/from/",
  GET_DESTINATION_PREMISE_ID:
    baseURL + "/premise-service/v1/premise/areas/premise-id/",
  POST_RETAIL_BOOKING: baseURL + "/shipment-booking-service/v1/cashbooking",

  //Address
  GET_RETAIL_DOCUMENT_DETAILS:
    baseURL + "/shipment-booking-service/v1/shipments/",
  UPDATE_RETAIL_DOCUMENT_DETAILS:
    baseURL + "/shipment-booking-service/v1/cashbooking/",
  GET_CHECK_GST_NUMBER: baseURL + "/premise-service/v1/premise/gst/",

  VALIDATE_PUBLIC_GST_NUMBER: baseURL + "/publicapi-service/v1/gst/",

  // UPDATE_RETAIL_DOCUMENT_DETAILS: baseURL + "/shipment-service/v1/shipment/",
  ///-----------------------------------------------------------///

  ///-----------------Credite Booking------------------------///
  GET_CLIENT_INNO: baseURLInnofulfill + "/client/",
  GET_COMPANY_INNO: baseURLInnofulfill + "/company/",
  GET_CLIENT_LIST: baseURL + "/client-onboard/v1/cp/clients",
  GET_DEPARTMENT_LIST: baseURL + "/client-onboard/v1/organizations/",
  GET_CONTENTDISCRIPTIN_LIST: baseURL + "/shipment-booking-service/v1/content",
  POST_CREDIT_DATA: baseURL + "/shipment-booking-service/v1/account-booking",
  ADDRESS_PINCODE: baseURL + "/pincode-service/v1/pincode/",
  VALIDATE_DOCUMENT_NUMBER: baseURLInnofulfill + "/shipment/document-number/validate",
  // GET_SERVICEABILITY_CHECK: bffBaseURL + "/v1/bff/serviceability/?pinCode=",
  RETURN_ADDRESS:
    baseURL +
    "/shipment-booking-service/v1/shipment/accountbooking/return-address/",
  UPDATE_ALL_BAGS_NEW1: baseURL + "/inscan-service/v1/mob-lcr-in-scan",
  EXIT_TRIP: baseURL + "/lcr-service/web/v1/exit-trip/",
  GET_RECENT_BOOKING: baseURL + "/shipment-booking-service/v1/recent-booking/",
  GET_ALL_BOOKING_STATUS:
    baseURL + "/shipment-service/v1/shipment-operation/allstatus",

  GET_RECIVER_NAME_ADDRESS:
    baseURL + "/shipment-booking-service/v1/shipment/userDetails/",

  //------------------------DISCOUNT-------------------------//
  GET_DISCOUNT_ELIGIBLE_LIST:
    baseURL + "/shipment-booking-service/v1/booking-discount-list",
  GET_DISCOUNT_LIST: baseURL + "/shipment-booking-service/v1/discount-list",
  APPLY_DISCOUNT: baseURL + "/shipment-booking-service/v1/apply-discount",
  GET_DISCOUNT_SENDERS_NAMES:
    baseURL + "/shipment-booking-service/v1/sendernames/",

  GET_DISCOUNT_PERCENT:
    baseURL + "/shipment-booking-service/v1/shipment/config",

  POST_NEW_RETAIL_BOOKING: baseURL + "/shipment-booking-service/v2/cashbooking",

  CHECK_CHILD_AWB_USED:
    baseURL + "/shipment-booking-service/v1/check-childawbnumber/",
  YESTARDAY_DATA: baseURL + "/hubops-report-service/v1/yeterday/report",

  CHECK_LOCAL: baseURL + "/pincode-service/v1/pincode-mapping/rate/",

  HUB_LOCK_MANIFEST: baseURL + "/sort-to-cp/v1/manifest/",

  SHIPMENT_TRACKER: baseURL + "/tracking-service/v1/hubops-tracking/",

  //Exceptions Reports
  GET_EXCEPTION_REPORT_DATA: baseURL + "/TableData",
  GET_EXCEPTION_ACTIONS_DATA: baseURL + "/actionData",
  POST_EXCEPTION_REPORT_DATA: baseURL + "/POST",
  PUT_EXCEPTION_REPORT_DATA: baseURL + "/PUT",

  UPDATE_EXCEPTION_REPORT_BAG: baseURL + "/bagging-service/v1/report/damage/",

  GET_HUB_LISTS: baseURL + "/premise-service/v1/hub-list",

  BOOKING_BARCODE_VALIDATION: baseURL + "/barcode-service/",
};
export default urls;
