/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react/react-in-jsx-scope */
// /* eslint-disable no-unused-vars */
// import React from "react";
import Heading from "../../../components/Heading";
import { Row, Col, Table, Tag, Card, Typography, Spin, message } from "antd";
import { useLocation } from "react-router-dom";
import { ReactComponent as Driver } from "../../../assests/images/LCRIcons/Driver.svg";
import { ReactComponent as Calendar } from "../../../assests/images/LCRIcons/calendar_month.svg";
import { ReactComponent as Road } from "../../../assests/images/LCRIcons/road.svg";
import { ReactComponent as Truck } from "../../../assests/images/LCRIcons/Truck.svg";
import { ReactComponent as FrontLoader } from "../../../assests/images/LCRIcons/Frontloader.svg";
import { ReactComponent as Loaction } from "../../../assests/images/LCRIcons/location_on-1.svg";
import { ReactComponent as Bag } from "../../../assests/images/LCRIcons/money_bag.svg";
import { ReactComponent as Packet } from "../../../assests/images/LCRIcons/package_2.svg";
import { ReactComponent as Bagg } from "../../../assests/images/LCRIcons/Recyclebag.svg";
import { ReactComponent as Event } from "../../../assests/images/LCRIcons/event_available.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import nodeAPI from "../../../api/nodeUrls";
import React, { useState } from "react";
import "./style.scss";
import { Collapse } from "antd";
import moment from "moment";
import {
  camelCase,
  capitaliseWithHyphen,
  normalizeString,
} from "../../../utils";
import API from "../../../api";
import { useEffect } from "react";

function LCRReportNew() {
  const { Panel } = Collapse;
  const history = useLocation();
  const report = history?.state;
  const [loading, setLoading] = useState(false);
  const { LCRTableDetails, LCR_BAG_SHIPEMNT_DETAILS } = nodeAPI;

  const [bagAndShipmentdata, setBagAndShipmentdata] = useState([]);
  const [shipmentCount, setshipmentCount] = useState({});
  const [activeKeys, setActiveKeys] = useState([]);
  const [panelContent, setPanelContent] = useState({});
  const [loadingPanels, setLoadingPanels] = useState({});

  useEffect(() => {
    getLCRDetailData();
  }, [report?.lcr_num]);

  const arr = [
    {
      label: "Created at: ",
      value: moment.utc(report?.created_date).format("DD-MM-YYYY hh:mm A"),
      icon: <Calendar />,
    },
    { label: "Travel Mode:", value: report?.travel_by, icon: <Road /> },
    {
      label: "Vehicle/Flight No: ",
      value: report?.vehicle_num,
      icon: <Truck />,
    },
    {
      label: "Co-Loader: ",
      value: report?.coloader || "",
      icon: <FrontLoader />,
    },
  ];

  const bagAndShipment = [
    {
      label: "Bag Out / Bag In: ",
      value: ` ${report?.bag_out_count}/${report?.bag_in_count}`,
      icon: <Bag />,
      received: +report?.bag_out_count === +report?.bag_in_count,
    },
    {
      label: "Shipment Out / Shipment In: ",
      value: ` ${report?.shipment_out_count}/${report?.shipment_in_count}`,
      icon: <Packet />,
      received: +report?.shipment_out_count === +report?.shipment_in_count,
    },
    {
      label: "Completed On: ",
      value: moment
        .utc(report?.latest_operation_time)
        .format("DD-MM-YYYY hh:mm A"),
      icon: <Event />,
    },
  ];
  const hubDeatils = [
    {
      label: "Driver Name: ",
      value: report?.driver_name,
      icon: <Driver />,
    },
    {
      label: "Route: ",
      value: report?.route_name,
      icon: <Loaction />,
    },
    {
      label: "Current Hub: ",
      value: camelCase(report?.from_hub),
      icon: <Loaction />,
    },
    {
      label: "Destination Hub: ",
      value: camelCase(report?.to_hub),
      icon: <Loaction />,
    },
  ];

  const fetchPanelData = async (openedPanel) => {
    const filterData = bagAndShipmentdata.find(
      (item) => item.awb_number == openedPanel
    );
    setLoadingPanels((prev) => ({ ...prev, [openedPanel]: true }));
    const aa = filterData.bag
      ? `${filterData?.awb_number}&isBag=true`
      : filterData?.awb_number;
    const response = await API.Api(
      LCR_BAG_SHIPEMNT_DETAILS + aa
    ).NodeGetWithPremise();
    if (response.status) {
      return response.response.data;
    } else {
      setLoading(false);
      message.error("Somthing went wrong");
    }
  };

  const columns = [
    {
      title: "Shipment No.",
      dataIndex: "barcode_no",
      key: "barcode_no",
      render: (text, record) => (
        <span>{record?.barcode_no || record?.awb_number}</span>
      ),
    },
    {
      title: "Destination Hub Name",
      dataIndex: "destination_hub",
      key: "destination_hub",
      render: (text) => <div>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Travel Status",
      dataIndex: "booking_status",
      key: "booking_status",
      render: (text, record) => (
        <div>
          <Tag
            color={"#C5F3D9"}
            style={{
              marginLeft: 8,
              borderRadius: "10px",
              color: "#475467",
              width: "150px",
              textAlign: "center",
              whiteSpace: "normal",
            }}
          >
            {normalizeString(text)}
          </Tag>
          {capitaliseWithHyphen(record?.current_destination)}
        </div>
      ),
    },
    {
      title: "Date & Time",
      key: "latest_operation_time",
      dataIndex: "latest_operation_time",
      render: (text) => (
        <div>{moment?.utc(text)?.format("DD-MM-YYYY hh:mm A")}</div>
      ),
    },
  ];

  const getLCRDetailData = async () => {
    setLoading(true);

    const response = await API.Api(
      LCRTableDetails + report?.vehicle_lcr_id
    ).NodeGetWithPremise();
    if (response.status) {
      let bagCount = 0;
      let shipmentCount = 0;

      response.response.data.forEach((item) => {
        if (item.bag) {
          bagCount += 1;
        } else {
          shipmentCount += parseInt(item.shipment_out_count);
        }
      });

      setshipmentCount({
        bagCount,
        shipmentCount,
      });
      setBagAndShipmentdata(response.response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const { Title, Text } = Typography;

  const onChange = async (keys) => {
    setActiveKeys(keys);

    const openedPanel = keys.find((key) => !activeKeys.includes(key));

    if (openedPanel) {
      if (!panelContent[openedPanel]) {
        const data = await fetchPanelData(openedPanel);
        setPanelContent((prev) => ({ ...prev, [openedPanel]: data }));
        setLoadingPanels((prev) => ({ ...prev, [openedPanel]: false }));
      }
    }
  };

  const isBagOrShipment = (isbag) => (
    <>
      {isbag.bag ? (
        <>
          <span>
            <Bagg /> Bag No.-
            <span className="vehicleContainerValue">{isbag?.awb_number}</span>
          </span>
          <span> {`(${isbag?.shipment_out_count} Shipments)`}</span>
        </>
      ) : (
        <span>
          <Packet /> Ind. Shipment-
          <sapn className="vehicleContainerValue">{isbag.awb_number}</sapn>
        </span>
      )}
      {activeKeys.includes(isbag?.awb_number) && (
        <Row>
          <Col lg={6}>
            <span className="vehicleContainerLabel"> Destination Hub:</span>
            <span className="vehicleContainerValue">
              {normalizeString(isbag?.premise_name)}
            </span>
          </Col>
          <Col lg={6}>
            <span className="vehicleContainerLabel">status:</span>
            <span className="vehicleContainerValue">
              {
                <Tag
                  color={"#C5F3D9"}
                  style={{
                    marginLeft: 8,
                    borderRadius: "10px",
                    color: "#475467",
                    width: "150px",
                    textAlign: "center",
                    whiteSpace: "normal",
                  }}
                >
                  {normalizeString(isbag?.status)}
                </Tag>
              }
            </span>
          </Col>
          <Col lg={6}>
            <span className="vehicleContainerLabel">Date & Time: </span>
            <span className="vehicleContainerValue">
              {moment
                .utc(isbag?.latest_operation_time)
                .format("DD-MM-YYYY hh:mm A")}
            </span>
          </Col>
          <Col lg={6}></Col>
        </Row>
      )}
    </>
  );
  const colorObj = {
    INITIATED: "#007BFF", // Bright Blue
    STARTED: "#17A2B8", // Teal
    "IN-TRANSIT": "#FFC107", // Amber/Yellow
    COMPLETED: "#28A745", // Green
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="p-2-tab">
          <Heading title={`LCR No. ${report?.lcr_num}`} />
          <Card
            style={{ borderRadius: 10, marginBottom: 20, marginTop: 20 }}
            bodyStyle={{ padding: 20 }}
          >
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col xs={24} sm={12} md={12}>
                <Title level={4} style={{ color: "#475467" }}>
                  Vehicle LCR NO.
                </Title>
                <Text strong>{report?.vehicle_lcr_id}</Text>
                <Tag
                  color={colorObj[report?.status]}
                  style={{ marginLeft: 8, borderRadius: "10px" }}
                >
                  {report?.status}
                </Tag>
              </Col>
            </Row>
            <Row className="pt-1" gutter={[16, 16]}>
              {arr.map((e, i) => (
                <LabelValue
                  item={e}
                  key={i}
                  index={i}
                  firstIndex={6}
                  allIndex={4}
                />
              ))}
            </Row>
            <Row className="pt-1">
              {hubDeatils.map((e, i) => (
                <LabelValue
                  item={e}
                  key={i}
                  index={i}
                  firstIndex={5}
                  allIndex={4}
                />
              ))}
            </Row>
            <Row className="pt-1">
              {bagAndShipment.map((e, i) => (
                <LabelValue
                  item={e}
                  key={i}
                  index={i}
                  firstIndex={5}
                  allIndex={5}
                  last={true}
                />
              ))}
            </Row>
          </Card>
        </div>
        <div className="container">
          <div style={{ backgroundColor: "#E5E5E5", borderRadius: "5px" }}>
            <div
              style={{ padding: " 20px", fontSize: "15px", fontWeight: "600" }}
            >
              <span className="vehicleContainerLabel"> STOP LCR:</span>{" "}
              <span className="vehicleContainerValue"> {report?.lcr_num} </span>
              |<span className="vehicleContainerLabel"> Destination Hub:</span>{" "}
              <span className="vehicleContainerValue">
                {camelCase(report?.to_hub)}
              </span>
              <span className="vehicleContainerLabel">
                ({shipmentCount?.bagCount} Bags
                {shipmentCount?.shipmentCount > 0 && "& Individual Shipment"})
              </span>
            </div>
            <Collapse onChange={onChange} activeKey={activeKeys}>
              {/* <div></div> */}
              {bagAndShipmentdata.map((item) => (
                <Panel
                  header={<div>{isBagOrShipment(item)}</div>}
                  key={item?.awb_number?.toString()}
                >
                  <Table
                    dataSource={panelContent[item?.awb_number?.toString()]}
                    pagination={false}
                    columns={columns}
                    loading={{
                      indicator: (
                        <img
                          src={Smcs_Loader}
                          style={{ height: "100px", width: "100px" }}
                        />
                      ),
                      spinning: loadingPanels[item?.awb_number?.toString()],
                    }}
                  />
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default LCRReportNew;

function LabelValue({ item, index, firstIndex, allIndex, last = false }) {
  return (
    <>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} className="align-center">
        {item.icon}
        <span className="vehicleContainerLabel">{`${item?.label}`}</span>

        <span className="vehicleContainerValue truncated-text">
          {item?.value}
        </span>
        {item.received === false && (
          <Tag
            color={item.received ? "#C5F3D9" : "red"}
            style={{ marginLeft: 8, borderRadius: "10px", color: "#475467" }}
          >
            Pending
          </Tag>
        )}
        {item.received === true && (
          <Tag
            color={item.received ? "#C5F3D9" : "red"}
            style={{ marginLeft: 8, borderRadius: "10px", color: "#475467" }}
          >
            Received
          </Tag>
        )}
      </Col>
    </>
  );
}
