/* eslint-disable no-unused-vars */
import React from "react";
import "./style.scss";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import backArrow from "../../../assests/images/backarrow.svg";
import { useNavigate } from "react-router-dom";
import { ManifestCard } from "./ManifestCard";
import { ManifestMiniCard } from "./ManifestMiniCard";
import ScannedShipment from "../../../components/ScannedShipment";
import Heading from "../../../components/Heading";

function ManifestList({
  name,
  // packet,
  // individual,
  color,
  children,
  packetStatus,
  shipmentStatus,
  packetAndShipmate,
  manifestFor,

  totalScannedShipment,
}) {
  const navigate = useNavigate();
  function routeHandler() {
    if (manifestFor == "surface") {
      // navigate("/child-scan");
      navigate("/scan");
    } else {
      // navigate("/child-scan");
      navigate("/scan");

      // navigate("/viewBooking-packet");
    }
  }

  return (
    <div className="scan-container" style={{ position: "relative" }}>
      <div className="scan-container-header">
        <div>
          {/* <span className="scan-container-header-backArrow">
            {name == "Manifest List" ? (
              <img src={backArrow} onClick={routeHandler} />
            ) : (
              ""
            )}
          </span>
          {name} */}
          <Heading title={"Manifest List"} />
        </div>
        {totalScannedShipment && (
          <div className="scan-container-header-packetContainer">
            {/* <span className="scan-item">Packets :</span>
          <span className="scan-item-val">{packet}</span>
          <span className="scan-item">Individual :</span>
          <span className="scan-item-val">{individual}</span> */}
            <ScannedShipment
              scannedData={totalScannedShipment}
              totalData={packetAndShipmate?.length}
            />
          </div>
        )}
      </div>
      <div className="scan-container-header-line"></div>
      <div className="scan-container-div">
        <Row gutter={12}>
          {packetAndShipmate &&
            packetAndShipmate.map((el, idx) => {
              if (
                Object.keys(el).includes("packetBarcode") &&
                el?.status === packetStatus
              ) {
                return (
                  <Col span={12} key={idx}>
                    <ManifestCard color={color} item={el} />
                  </Col>
                );
              }
              if (
                Object.keys(el).includes("awbNumber") &&
                el?.status == shipmentStatus
              ) {
                return (
                  <Col span={12} key={idx}>
                    <ManifestMiniCard
                      color={color == "yello" ? "skyblue" : color}
                      item={el}
                    />
                  </Col>
                );
              }
            })}
        </Row>
        <div className="scan-container-div-childern">{children}</div>
      </div>
    </div>
  );
}
ManifestList.PropTypes = {
  name: PropTypes.string,
  packet: PropTypes.string,
  individual: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.object,
};

export default ManifestList;
