/* eslint-disable no-unused-vars */
// eslint-disable-next-line max-len
import { ReactComponent as AcctIcon } from "../assests/images/SVG/accountBkIcon.svg";
import { ReactComponent as CashIcon } from "../assests/images/SVG/cashBooking.svg";
import { ReactComponent as DrcIcon } from "../assests/images/SVG/drsScanIcon.svg";
import { ReactComponent as HomeIcon } from "../assests/images/SVG/Home.svg";
import { ReactComponent as ViewIcon } from "../assests/images/SVG/viewMsg.svg";
import { ReactComponent as SidebarSetting } from "../assests/images/SVG/sidebar-setting.svg";

import { ReactComponent as HomeIconFilled } from "../assests/images/SVG/home-filled.svg";
import { ReactComponent as CashIconFilled } from "../assests/images/SVG/cash-booking-filled.svg";
import { ReactComponent as ViewIconFilled } from "../assests/images/SVG/viewMsgFilled.svg";
import { ReactComponent as SidebarSettingFilled } from "../assests/images/SVG/sidebar-setting-filled.svg";
import { ReactComponent as HubSettingOpen } from "../assests/images/HubAdmineLogo/setting-open.svg";
import { ReactComponent as HubSettingClose } from "../assests/images/HubAdmineLogo/setting-close.svg";
import { ReactComponent as SorterIcon } from "../assests/images/middleMile/sorter-icon.svg";

import constants from "../constants/constants";

const { ROLES } = constants;
const { CP_ADMIN, HO_ADMIN, HUB_ADMIN } = ROLES;

export const sideMenuList = [
  {
    active: "1",
    icon: HomeIcon,
    fillIcon: HomeIconFilled,
    title: "Home",
    id: "home",
    route: "/dashboard",
    role: "",
  },
  {
    active: "sub1",
    icon: HubSettingClose,
    fillIcon: HubSettingOpen,
    title: "Onboard Setting",
    id: "onboard-setting",
    route: "/onboarding-settings",
    role: [HUB_ADMIN],
  },
  // {
  //   active: "2",
  //   icon: CashIcon,
  //   fillIcon: CashIconFilled,
  //   title: "Retail Booking",
  //   id: "cash-booking",
  //   route: "/retail-booking",
  //   role: [CP_ADMIN],
  // },
  {
    active: "3",
    icon: AcctIcon,
    fillIcon: AcctIcon,
    title: "Credit Booking",
    id: "account-booking",
    route: "/credit-booking",
    role: [CP_ADMIN],
  },
  {
    active: "4",
    icon: DrcIcon,
    fillIcon: DrcIcon,
    title: "DRS Scanning",
    id: "drs-scanning",
    route: "",
    role: [CP_ADMIN],
  },
  {
    active: "5",
    icon: SidebarSetting,
    fillIcon: SidebarSettingFilled,
    title: "Settings",
    id: "settings",
    route: "/masters",
    role: [HO_ADMIN],
  },
  {
    active: "6",
    icon: ViewIcon,
    fillIcon: ViewIconFilled,
    title: "View Booking",
    id: "view-booking",
    route: "/viewBooking",
    role: [CP_ADMIN],
  },
  {
    active: "7",
    icon: SorterIcon,
    title: "Sorter UI",
    id: "sorter-ui",
    route: "https://sorter.innofulfill.com/",
    role: [HUB_ADMIN],
    directReRoute: true,
  },
];
