import {
  Button,
  Form,
  Input,
} from "antd";
import React from "react";
function LoginForm({errorMessage,onFinish,handleForgot}) {
  return (
    <>
      <div className="login-container-headding">
        Login To Your Account
      </div>

      <div className="login-container-subheadding">
        Prompt, Quick & Reliable Services
      </div>

      <div className="error">
        <p>{errorMessage}</p>
      </div>
      <Form
        // style={{ marginTop: "5%" }}
        onFinish={onFinish}
        autoComplete="on"
        initialValues={
          {
            // username: "priyaranjan@hutechsolutions.com",
            // password: "Hutech@1234",
            // name:"sdhjdhhjs"
          }
        }
      >
        <div
          className="login-container-email"
          style={{ marginTop: 20 }}
        >
          Email
        </div>

        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Enter your email address",
            },
          ]}
        >
          <Input
            className="login-container-email-input"
            placeholder="Enter your email address"
            // maxLength={50}
            bordered={false}
          />
        </Form.Item>
        <div
          className="login-container-email"
          style={{ marginTop: "30px" }}
        >
          Password
        </div>
        <div>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Enter your password",
              },
            ]}
          >
            <Input.Password
              className="login-container-email-input"
              placeholder="Password"
              bordered={false}
            />
          </Form.Item>
        </div>
        <div className="login-container-remember">
          {/* <div
                style={{
                display: "flex",
                columnGap: "10px",
                }}
            >
            <Checkbox
              onChange={(e) => setRememberMe(!rememberMe)}
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  setRememberMe(!rememberMe);
                  e.preventDefault();
                }
              }}
              defaultChecked={rememberMe}
              checked={rememberMe}
            />
            <span className="rememberMe">Remember me</span>
          </div> */}
          <div
            className="login-container-forgot"
            onClick={handleForgot}
          >
            Forgot Password ?
          </div>
        </div>

        <Form.Item>
          <div className="login-container-btndiv">
            <Button
              className="save-btn mt-2"
              size="large"
              htmlType="submit"
              block
            >
              LOGIN
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default LoginForm;
